import { AuthenticationManager } from '../authentication/AuthenticationManager';

const REGISER_DEVICE_PATH = '/api/device/register';
const REGISTERED_DEVICES_PATH = '/api/device/';

const registerDevice = async (deviceName, deviceCode) => {
    const url = REGISER_DEVICE_PATH; // Replace with your API endpoint
    const data = {
        deviceName: deviceName,
        deviceCode: deviceCode
    };

    try {
        const response = await fetch(url, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthenticationManager.getToken()}`
            },
            body: JSON.stringify(data) 
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return;
    } catch (error) {
        return null;
    }
};

const fetchDevices = async () => {
    const url = REGISTERED_DEVICES_PATH; 

    try {
        const response = await fetch(url, {
            method: 'GET', // Specifies the request type
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthenticationManager.getToken()}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        return null;
    }
};



const deleteDevice = async (deviceId) => {
    const url = REGISTERED_DEVICES_PATH + "/" + deviceId; 
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${AuthenticationManager.getToken()}`
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      return;
    } catch (error) {
      console.error('Error deleting item:', error);
      throw error; 
    }
  };

// const deleteDevice = async (deviceId) => {
//     const url = REGISTERED_DEVICES_PATH + "/" + deviceId; 

//     try {
//         return fetch(url, {
//             method: 'DELETE', 
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Authorization': `Bearer ${AuthenticationManager.getToken()}`
//             }
//         })
//         // }) .then(async response => {
//         //     if (!response.ok) {
//         //         // get error message from body or default to response status
//         //         const error = response.status;
//         //         return Promise.reject(error);
//         //     }
            
//         //     return true;
//         // })
//         // .catch(error => {
//         //     throw new Error(`HTTP error! Status: ${error}`);
//         // });


//     } catch (error) {
//         console.error('Error deleting device:', error);
//         throw error;    
//     }
// };

export { registerDevice, fetchDevices, deleteDevice };
