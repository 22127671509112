import { AuthenticationManager } from '../authentication/AuthenticationManager';

const ALL_TALISMANS_PATH = '/api/talisman/allWithUserDetails';

const fetchAllTalismans = async () => {
    try {
        const response = await fetch(ALL_TALISMANS_PATH, {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error('Error fetching talismans'); 
        }

        const data = await response.json();
        return data;  

    } catch (error) {
        console.log('Error:', error.message);
        return null;  
    }
};

const deleteTalisman = async(id) => {
    const url = `/api/talisman/${id}`; // Construct the endpoint URL

    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AuthenticationManager.getToken()}`
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Optional: Return response data if needed
        return response.status === 204 ? null : await response.json();
    } catch (error) {
        console.error('Error deleting talisman:', error);
        throw error; // Re-throw the error to handle it in the calling code
    }
}


export { fetchAllTalismans, deleteTalisman }
