import React, { useState } from 'react';
import { Button, Modal, Form, Image } from 'react-bootstrap';
import { registerDevice } from '../devices/DeviceAPI';

const LinkDeviceModal = ({ showModal, handleModalClose }) => {
    const [codeValidationError, setCodeValidationError] = useState(false);
    const [nameValidationError, setNameValidationError] = useState(false);
    const [deviceCode, setDeviceCode] = useState('');
    const [deviceName, setDeviceName] = useState('');

    //Register the device
    const handleRegisterDevice = () => {

        if (!isInputValid()) {
            return;
        }

        registerDevice(deviceName, deviceCode).then(() => {
            handleModalClose();
        });
    };


    // Validation
    const isInputValid = () => {
        let validated = true;
        if (deviceName.length < 3) {
            setNameValidationError(true);
            validated = false;
        } else {
            setNameValidationError(false);
        }

        if (deviceCode.length !== 5) {
            setCodeValidationError(true);
            validated = false;
        } else {
            setCodeValidationError(false);
        }

        return validated;
    }

    const handleNameInputChange = (e) => {
        const input = e.target.value;
        setDeviceName(input);
        setNameValidationError(false);
    }

    const handleCodeInputChange = (e) => {
        let input = e.target.value;

        if (input.length > 5) {
            input = input.substring(0, 5);
        }

        setDeviceCode(input);
        setCodeValidationError(false);
    }

    // Cleanup
    const handleModalShow = () => {
        reset();
    };

    const reset = () => {
        setDeviceName('');
        setDeviceCode('');
    }

    return (

        <Modal show={showModal} onHide={handleModalClose} onShow={handleModalShow}>
            <Modal.Header closeButton>
                <Modal.Title>Link Your Device</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="deviceName">
                        <Form.Label>Device name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="ex. My Garmin Foreunner"
                            value={deviceName}
                            isInvalid={nameValidationError}
                            onChange={handleNameInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Device name must be at least 3 characters.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="deviceCode">
                        <Form.Label>Device Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter the 5-digit code your device."
                            value={deviceCode}
                            isInvalid={codeValidationError}
                            onChange={handleCodeInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Device code must be exactly 5 characters.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <br />
                    <Image
                        src="/images/watch-with-code.png"
                        alt="Help text"
                        className="img-fluid mb-3 d-block and mx-auto"  // Bootstrap class to make image responsive
                        style={{ width: "150px" }}  // You can adjust the size using this
                    />
                </Form>
                <p className="mt-3 text-muted">
                    Launch the TalisFit ConnectIQ app on your Garmin device. Then enter the 5 digit code displayed on the device to activate your device.
                </p>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleModalClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleRegisterDevice}>
                    Link Device
                </Button>
            </Modal.Footer>
        </Modal>

    );
};

export default LinkDeviceModal;