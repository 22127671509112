import { AuthenticationManager } from '../authentication/AuthenticationManager';

const USER_PROFILE_PATH = '/api/user/profile';

export const fetchUserProfile = async () => {
    try {
        const response = await fetch(USER_PROFILE_PATH, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${AuthenticationManager.getToken()}`,
            },
        });

        if (response.status === 401 || response.status === 404) {
            AuthenticationManager.logOut();
            throw new Error('Unauthenticated');
        }

        if (!response.ok) {
            throw new Error('Error fetching profile'); 
        }

        const data = await response.json();
        return data;  

    } catch (error) {
        console.log('Error:', error.message);
        return null;  
    }
};
