import React from 'react';
import { Table, Pagination } from 'react-bootstrap';

const LeaderboardTable = ({ data, userId, currentPage, totalPages, onPageChange }) => {
  const renderRow = (item, index) => {
    const isCurrentUser = item.userId === userId;
    return (
      <tr key={item._id} className={isCurrentUser ? 'table-success' : ''}>
        <td>{index + 1 + (currentPage - 1) * 10}</td>
        <td>
          <img
            src={item.userDetails.picture || 'https://via.placeholder.com/40'}
            alt={item.userDetails.displayName}
            style={{ width: 40, height: 40, borderRadius: '50%' }}
          />
        </td>
        <td>{item.userDetails.displayName}</td>
        <td>{item.distanceTravelled ?? 0}</td>
      </tr>
    );
  };

  const renderPagination = () => {
    const items = [];
    for (let page = 1; page <= totalPages; page++) {
      items.push(
        <Pagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => onPageChange(page)}
        >
          {page}
        </Pagination.Item>
      );
    }
    return <Pagination>{items}</Pagination>;
  };

  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Avatar</th>
            <th>Name</th>
            <th>Distance Travelled</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => renderRow(item, index))}
        </tbody>
      </Table>
      {totalPages > 1 && renderPagination()}
    </>
  );
};

export default LeaderboardTable;
