import { React, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { formatDate } from '../../localization/DateTimeFormatter';

const PopupContent = ({ user, talisman, onDelete, closePopup }) => {
    const [showModal, setShowModal] = useState(false); 

    const isOwner = user?._id === talisman.userId;

    const handleDelete = () => {
        onDelete(talisman._id); // Trigger the delete logic
        closePopup(); // Close the popup
        setShowModal(false); // Close the modal
    };

    return (
        <>
        <div class="talisman-popup" style={{ display: 'flex', alignItems: 'center' }}>
            {/* Left Column: Profile Picture */}
            <div style={{ flex: 1, textAlign: 'center' }}>
                <img 
                    src={talisman.userDetails.picture}
                    alt={`${talisman.userDetails.displayName}'s profile`} 
                    style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: '50%' }}
                />
            </div>

            {/* Right Column: Details */}
            <div style={{ flex: 2, paddingLeft: 5 }}>
                <h4 style={{ margin: 0 }}>{talisman.userDetails.displayName || 'Placeholder Name'}</h4>
                <p style={{ margin: '5px 0' }}><b>Created:</b> { formatDate(talisman.spawnDate) }</p>
                <p style={{ margin: '5px 0' }}><b>Updated:</b> { formatDate (talisman.lastUpdate) }</p>
                <p style={{ margin: '5px 0' }}><b>Distance:</b> {talisman.distanceTraveled}</p>
                {isOwner && (
                    <button 
                        onClick={() => {
                            setShowModal(true)
                        }}
                        style={{
                            marginTop: 10,
                            padding: '5px 10px',
                            backgroundColor: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: 5,
                            cursor: 'pointer',
                        }}
                    >
                        Delete
                    </button>
                )}
            </div>
        </div>

        {/* Confirmation Modal */}
        <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete your talisman? <b>This action cannot be undone!</b> All XP that you 
                    have earned will also be lost.</p><p>Is your talisman stranged in an unreachable place? Please 
                    contact support to have it repositioned.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
        </Modal>

        </>
    );
};

export default PopupContent;
