const ENV_PATH = '/api/env/variables';

export const fetchEnvironment = async () => {
    try {
        const response = await fetch(ENV_PATH, {
            method: 'GET'
        });


        if (!response.ok) {
            throw new Error('Error fetching profile'); 
        }

        const data = await response.json();
        return data;  

    } catch (error) {
        console.log('Error:', error.message);
        return null;  
    }
};
