import { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import PopupContent from './PopupContent';

const Marker = ({ map, user, talisman, onDelete }) => {
    useEffect(() => {
        if (!map || !talisman) return;

        // Create a container div for the popup content
        const popupContainer = document.createElement('div');
        const root = createRoot(popupContainer);
        root.render(
            <PopupContent 
                user={user} 
                talisman={talisman} 
                onDelete={onDelete} 
                closePopup={() => popup.remove()}
            />);

        // Create Popup and attach the rendered container
        const popup = new mapboxgl.Popup({ offset: 25, maxWidth: '500px' }).setDOMContent(popupContainer);

        // Create Marker and attach the popup
        const marker = new mapboxgl.Marker()
            .setLngLat(talisman.currentPosition.coordinates)
            .setPopup(popup)
            .addTo(map);

        // Cleanup on unmount
        return () => {
            marker.remove();
            setTimeout(() => root.unmount(), 0); 
        };
    }, [map, user, talisman, onDelete]);

    return null; // Marker is rendered on the Mapbox map, not in the DOM
};

export default Marker;
