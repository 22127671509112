import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { deleteTalisman, fetchAllTalismans } from '../../talisman/Talisman';
import Marker from './Marker';

const Map = ({ user }) => {
  const mapRef = useRef();
  const mapContainerRef = useRef();
  const [talismans, setTalismans] = useState([]);

  useEffect(() => {

    if (!mapRef.current) {

      mapboxgl.accessToken = 'pk.eyJ1Ijoia3NoYWZmMDMiLCJhIjoiY2poMTZkdm81MDBybjJxcGIxMmI3eHdjcSJ9.ou0XqCZckeVfJ3S03y2hSA';

      // Initialize Map
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [4.4802, 52.1636],
        zoom: 12,
      });

      // Fetch Talismans
      fetchAllTalismans().then((data) => {
        setTalismans(data || []);
      });
    } 

    // Calculate map center or bounds
    if (user) {
      // Center on the user's talisman
      const userTalisman = talismans.find((talisman) => talisman.userId === user._id);
      if (userTalisman) {
          mapRef.current.setCenter(userTalisman.currentPosition.coordinates);
          mapRef.current.setZoom(14); // Optional: Adjust zoom level for user's talisman
      }
  } else if (talismans.length > 0) {
      // Fit bounds to include all talismans
      const bounds = new mapboxgl.LngLatBounds();
      talismans.forEach((talisman) => {
          bounds.extend(talisman.currentPosition.coordinates);
      });

      mapRef.current.fitBounds(bounds, {
          padding: 20, // Optional: Padding around the bounds
          maxZoom: 14, // Optional: Maximum zoom level
          duration: 1000, // Optional: Animation duration in milliseconds
      });
  }



    //return () => mapRef.current.remove(); 
  }, [user, talismans]);

  const handleDeleteTalisman = (talismanId) => {
    deleteTalisman(talismanId);
    setTalismans((prev) => prev.filter((talisman) => talisman._id !== talismanId));
  };

  return (
    <div id="map-container" ref={mapContainerRef} style={{ height: '100vh' }}>
      {talismans.map((talisman) => (
        <Marker
          key={talisman._id}
          map={mapRef.current}
          user={user}
          talisman={talisman}
          onDelete={handleDeleteTalisman}
        />
      ))}
    </div>
  );
};

export default Map;
