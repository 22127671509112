import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Table, Pagination, Alert } from 'react-bootstrap';
import LeaderboardTable from './LeaderboardTable';

const LeaderboardPage = ({ user }) => {
  const [activeTab, setActiveTab] = useState('distance'); 
  const [data, setData] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalPages, setTotalPages] = useState(1); 
  const [loading, setLoading] = useState(false); 

  const fetchLeaderboard = async (tab, page) => {
    setLoading(true);
    try {
      const response = await fetch(`/api/talisman/leaderboard`);
      const result = await response.json();
      setData(result.data); // Update leaderboard data
      setTotalPages(result.totalPages); // Update total pages
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaderboard(activeTab, currentPage);
  }, [activeTab, currentPage]);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    setCurrentPage(1); // Reset to the first page when switching tabs
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="leaderboard-page">
      <h1 className="text-center mb-4">Leaderboards</h1>
      <Tabs
        id="leaderboard-tabs"
        activeKey={activeTab}
        onSelect={handleTabChange}
        className="mb-3"
      >
        <Tab eventKey="distance" title="Distance Travelled">
          {loading ? (
            <Alert variant="info">Loading Distance Leaderboard...</Alert>
          ) : (
            <LeaderboardTable
              data={data}
              userId={user?._id}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </Tab>
        <Tab eventKey="xp" title="Experience Points">
          {/* {loading ? (
            <Alert variant="info">Loading XP Leaderboard...</Alert>
          ) : (
            <LeaderboardTable
              data={data}
              userId={user?._id}
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )} */}
          Todo
        </Tab>
      </Tabs>
    </div>
  );
};

export default LeaderboardPage;
