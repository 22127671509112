import React from 'react';
import { Link } from 'react-router-dom';

function Contact() {
  return (
    <>
    Contact - TODO
    </>
  );
}

export default Contact;
