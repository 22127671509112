function formatDate(isoString) {
    const date = new Date(isoString);

    // Options for formatting the date
    const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

    // Format the date and time separately
    const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(date);
    const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(date);

    // Combine the date and time
    return `${formattedDate} @ ${formattedTime}`;
}

export { formatDate }