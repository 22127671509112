const JWT_TOKEN_KEY = 'jwtToken';

const AuthenticationManager = {
  isAuthenticated() {
    let token = this.getToken();
    return token != null && typeof token != "undefined";
  },

  getToken() {
    return localStorage.getItem(JWT_TOKEN_KEY);
  },

  storeToken(token) {
    localStorage.setItem(JWT_TOKEN_KEY, token);
  },

  logOut() {
    localStorage.removeItem(JWT_TOKEN_KEY);  // Use removeItem instead of clear to just remove this key
  },

  hasTokenOnQueryString() {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('token')) {
        return true;
      }
  },

  storeTokenOnQueryString() {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    this.storeToken(token);
    window.location = "/";
  }


};

export { AuthenticationManager };
